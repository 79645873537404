import { useState } from 'react'
import apiHost from './apiHost'

//Repalce variables
const reaplaceVariables = (string, variables) => {
  Object.keys(variables).forEach( key => {
      string = string.replace(`$${key}`, variables[key])
  })
  return string
}

const useMutation = (query, {variables, onCompleted}) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const trigger = (options) => {
    // Set loading to ture
    setLoading(true)

    // Make sure options are defined
    options = options || {}

    query = reaplaceVariables(query, options.variables || variables || {} )
    fetch( apiHost, {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: query
      }),
    })
    .then(response=>response.json())
    .then( response => {
      //Handle graph ql errors
      if(response.errors) throw response.errors.map( error => error.message ).join(', ')
      if(response.data) {
        //Get name of first key from response data data
        const queryName = Object.keys(response.data)[0]
        //Use that key and if onComplete defined send the shallow data shape
        if(onCompleted) onCompleted(response.data[queryName])
        // setLoading(false)
      }
    })
    .catch( err => {
      console.error(err)
      setError( `Error: ${err.toString()}` )
    })
  }




  return [

    trigger,

    {
      error,
      loading //TODO: (data === null && error === null) ? true : false
    }
  ]
}

export default useMutation
