import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from '@patomation/react-ui-components'
import { navigate } from 'gatsby'

const CheckoutTabs = ({active, step}) =>
    <Tabs
      active={active}
      data={['Cart', 'Info', 'Payment']}
      step={true}
      onClick={(item) => {
        console.log(item);
        if(item === 'Cart') navigate('/cart')
        if(item === 'Info') navigate('/checkout')
        if(item === 'Payment') navigate('/payment')
      }}/>

CheckoutTabs.propTypes = {
  active: PropTypes.string,
  step: PropTypes.array
}

export default CheckoutTabs
