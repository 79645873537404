import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout.js'
import { List, Icon, IconButton, Input, Gutter, Grid, Row, Button, Hr, Error, Modal, Loader } from '@patomation/react-ui-components'
import { Link } from 'gatsby'
import useGlobal from '../hooks/useGlobal'
import CheckoutTabs from '../components/CheckoutTabs'
import Totals from '../components/Totals'
import moment from 'moment'
import useMutation from '../hooks/useMutation'
import useQuery from '../hooks/useQuery'
import queryParams from '../modules/queryParams'

const ADD_CART = `
mutation {
  addCart (input: $input){
    _id
  }
}
`

const CART = `
query {
  cart(id: "$id"){
    items {
      date
      basePrice
      personPrice
      title
      travelers
      slug
    }
  }
}
`

const Cart = ({location}) => {
  console.log('RENDER');

  const [ globalState, setGlobalState ] = useGlobal({cart:[]})



  const [ dateErrors, setDateErrors ] = useState([])
  const [ validationError, setValidationError ] = useState()
  const validateDate = (date, index) => {
    //Get cart dates
    let cart = globalState.cart.map( item => item.date)
    //Ignore this index date
    cart.splice(index, 1)
    let valid = false;
    //IS AFTER TODAY
    if( moment().diff(date) > 0 ) {
      //Is today or in the past
      setValidationError('Must be after today')
    } else if ( cart.includes(date) ) {
      setValidationError('Tours must be on different days')
    } else if ( !date ) {
      setValidationError('Date required')
    } else {
      //Is after today
      setValidationError(null)
      valid = true;
    }

    let newDateErrors = [...dateErrors]
    newDateErrors[index] = !valid
    setDateErrors(newDateErrors)
  }


  //Set total price
  const calculatePrice = () =>
    globalState.cart.reduce( (acc, {travelers, basePrice, personPrice}) => {
      acc += basePrice && personPrice
        ? basePrice + (personPrice * travelers)
        : 0 //use zero in case something is undefined so NaN doesn't show up
      return acc
    }, 0)

  const [ cartLink, setCartLink ] = useState()
  const [ addCart ] = useMutation(ADD_CART, {
    onCompleted: (result) => {
      setCartLink(`${location.origin}${location.pathname}/?id=${result._id}`)
    }
  })


  const cartId = queryParams(location.href).id

  const [ complete, setComplete ] = useState(false)
  const { data, loading } = useQuery(CART, {
    variables:{ id: cartId },
    disabled: !cartId,
  })

  useEffect(() => {
    if(data) setGlobalState({cart: data.cart.items})
  }, [data])

  if(!complete && cartId && loading ) return (
    <Layout aboveMain={<div></div>}>
      <Loader
        message='Loading Cart, Please Wait'
        progressBar={true}
        progressBarColor='#86AC41'
        progressBarTime={10000}
        complete={!loading}
        spinner={false}
        onComplete={() => {
          setComplete(true)
        }}/>
    </Layout>
  )

  return (
   <Layout aboveMain={<CheckoutTabs active='Cart'/>}>

    <Modal show={!!cartLink} maxWidth='300px' onClose={()=>{setCartLink(null)}} >
      <Input
        type='textarea'
        value={cartLink}
        onChange={() => {}}
        />
    </Modal>



    <section>

    <List>

      {globalState.cart.map( (item, index) => {
        const {title, travelers, date, basePrice, personPrice, slug} = item
        return (
          <Grid
            className='cart__item'
            col={12}
            key={`cart_item_${index}`}
            gap='1rem'
            style={{ alignItems: 'center', paddingBottom: '1rem' }}>

            <h4
              className='cart__item__title'
              style={{
                gridColumn: 'auto / span 6'
              }}>
              <Link to={slug}>
                {title}
              </Link>
            </h4>

            <Input
              className='cart__item__travelers'
              label='Travelers'
              value={travelers}
              type='number'
              min={1}
              style={{ gridColumn: 'auto / span 1'}}
              onChange={(e)=>{
                let cart = [...globalState.cart]
                cart[index].travelers = parseInt(e.target.value)
                setGlobalState({cart})
              }}>
                1
              </Input>

            <Input
              className='cart__item__date'
              label='Date'
              value={date}
              onBlur={()=>{
                validateDate(date, index)
              }}
              onChange={(e)=>{
                let cart = [...globalState.cart]
                cart[index].date = e.target.value
                setGlobalState({cart})
                validateDate(e.target.value, index)
              }}
              error={dateErrors[index]}
              type='date'
              style={{ gridColumn: 'auto / span 3' }}
              />

            <div
              className='cart__item__price'
              style={{ gridColumn: '11 / span 1', textAlign: 'right' }}>
              $ {basePrice + (personPrice * travelers)}
            </div>

            <IconButton
              className='cart__item__remove'
              style={{ gridColumn: '12 / span 1' }}
              color='#324851'
              hoverColor='red'
              name='remove_shopping_cart'
              onClick={() => {
                let cart = [...globalState.cart]
                cart.splice(index, 1)
                setGlobalState({cart})
              }}/>
          </Grid>
        )
      })}
      { validationError ? <Error>{validationError}</Error> : null }
    </List>


    <Gutter /><Hr/><Gutter />
    <Totals totalPrice={calculatePrice()} />
    <Gutter /><Hr/><Gutter />

    <Row>

      <Link to='/packages'>
        <Button color='#86AC41' kind='outline'>
          <Icon name='keyboard_backspace' color='#86AC41'/><Gutter vertical />
          browse more packages
        </Button>
      </Link>


      <Gutter vertical />

      <Button
        title='share'
        kind='outline'
        color='#86AC41'
        onClick={() => {
          addCart({variables: {
            input: JSON.stringify(globalState.cart).replace(/\"([^(\")"]+)\":/g,"$1:") // eslint-disable-line
          }})
        }}/>

      <Gutter vertical />

      <Link to='/checkout' style={{marginLeft: 'auto'}}>
        <Button
          title='checkout'
          disabled={validationError ? true : false}
          background='#86AC41' color='#324851'/>
      </Link>

    </Row>
    </section>
   </Layout>
 )

}

export default Cart
