import React from 'react'
import PropTypes from 'prop-types'

import { Row } from '@patomation/react-ui-components'

const Totals = ({totalPrice}) => <>

<Row>
  <label>Total Cost:</label>
  <label style={{marginLeft: 'auto'}}>USD {totalPrice}.00</label>
</Row>
<Row style={{color: 'red'}}>
  <label>30% Deposit Payable Now:</label>
  <label style={{marginLeft: 'auto'}}>USD {Math.round(totalPrice * 0.3)}.00</label>
</Row>
<Row>
  <label>Remainder:</label>
  <label style={{marginLeft: 'auto'}}>USD {Math.round(totalPrice * 0.7)}.00</label>
</Row>

</>

Totals.propsTypes = {
  totalPrice: PropTypes.number.required
}

Totals.defaultProps = {
  totalPrice: 0
}

export default Totals
