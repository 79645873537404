import { useState, useEffect } from 'react'

//Gatsby patch
const localStorage = typeof window !== 'undefined'
  // use actual local storage
  ? window.localStorage
  // Use local storage mock
  : {
    setItem: () => {},
    getItem: () => { return "{}" }
  }

// array of listenders
let listeners = []
// state object
let state = {}

// set state handeler
const setState = (newState) => {
  //Merge state with new state
  state = { ...state, ...newState }
  //BACKUP TO LOCAL STORAGE
  localStorage.setItem('state', JSON.stringify(state))

  // trigger events for each subscribed listener
  listeners.forEach((listener) => {
    //Pass through the new state object to subscriber's local state
    listener(state)
  })
}

const storedState = JSON.parse(localStorage.getItem('state')) || {}

const useGlobal = (initialState) => {

  // If inital state is defined
  if(initialState !== undefined) {
    // For each key in initial state object
    Object.keys(initialState || {}).forEach( key => {
      //If state key value is undefined then set it with our initial value
      if( state[key] === undefined ) {
        // Use localStorage or  initial value
        state[key] = storedState[key] || initialState[key]
      }
    })
  }


  // Create new listener useState handeler function
  const newListener = useState()[1]
  // Use effect once
  useEffect(() => {
    // Store listener into array
    listeners.push(newListener)

    //Track index/id so we can remove the listener later
    const id = listeners.length-1

    // Unmount
    return () => {
      //Unsubscribe listener
      listeners.splice(id, 1)
    }
  }, [newListener])

  //Return object and handeler function
  return [state, setState]
}

export default useGlobal
