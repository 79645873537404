import React from 'react'
import { Link } from 'gatsby'

import { IconButton } from '@patomation/react-ui-components'

import useGlobal from '../hooks/useGlobal'

const CartButton = () => {

  const [globalState] = useGlobal({cart:[]});

  return (
    <Link to='/cart'>
      <IconButton
        badge={globalState.cart.length > 0}
        name='shopping_cart'
        color='#324851' />
    </Link>
  )
}
export default CartButton
