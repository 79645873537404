import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Nav, Brand, Gutter } from '@patomation/react-ui-components'
import find from '@patomation/find'
import A from './A'
import CartButton from './CartButton'
import Logo from './Logo'

const NavBar = () => {
  const data = useStaticQuery(graphql`
    query Navigation {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/menu/"}}) {
        edges {
          node {
            frontmatter {
              menu {
                title
                link
              }
            }
          }
        }
      }
    }
  `)


  return (
    <Nav background='#86AC41' color='#324851'>

      <Brand>
        <Logo/>
        <Gutter/>
        <div style={{
          fontSize:'0.7rem',
          lineHeight: '0.7rem',
          fontWeight: 'bold',
          color: '#000000'
        }}>
          <div>License No: 24-00146</div>
          <div>CM Northern Thai Escapes</div>
        </div>

      </Brand>

      {find('menu', data).map(item =>
        <span key={`nav_item_${item.title}`}>
          <A to={item.link}>{item.title}</A>
        </span>
      )}

      <CartButton />

    </Nav>
  )
}
export default NavBar
