/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import '../styles/main.scss'
import SEO from '../components/seo'
import NavBar from '../components/NavBar'
import FooterBar from '../components/FooterBar'
import { Brand, Gutter, Hero, Footer, Grid, Icon, Breakpoints } from '@patomation/react-ui-components'
import TalkTo from './TalkTo'

const Default = ({
  children, aboveMain, belowMain,
  title,
  description,
  hero_image
}) => {

return <>

{ title ? <SEO title={title} description={description}/> : null }


<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous"/>

<TalkTo/>

<NavBar />

{ title || description || hero_image ?
<Hero
  title={title}
  description={description}
  background='#324851'
  image={hero_image}/> : null }

<main style={{
  flex: '1 0 auto',
  position: 'relative',
}}>

  { aboveMain }

  <main
    className='main__max-width'
    style={{
      padding: '1rem',
      minWidth: '1px',
      maxWidth: '1000px',
      margin: '0 auto',
      minHeight: '200px'
  }}>

  {children}

  </main>

  { belowMain }

</main>

<FooterBar />

</>}
export default Default
