import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'


const A = ({children, to}) => {
  return to && to.includes('http')
  ? <a href={to}>{children}</a>
  : <Link to={to || '/'}>{children}</Link>

}

A.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  to: PropTypes.string
}

export default A
