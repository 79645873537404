const queryParams = (url) => {
  if(url === undefined) return {}
  const query = url.split('?')[1]
  //If there are no query params
  if(query === undefined) return {}

  return query.split('&').reduce((acc,item) => {
    const [key, value] = item.split('=')
    acc[key] = value
    return acc
  }, {})
}

export default queryParams
