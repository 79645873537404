import { useState, useEffect } from 'react';
import apiHost from './apiHost'

//Repalce variables
const reaplaceVariables = (string, variables) => {
  Object.keys(variables).forEach( key => {
      string = string.replace(`$${key}`, variables[key])
  });
  return string;
}

export default (query, options = {}) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  // const [loading, setLoading] = useState(true)

  const { variables, disabled, onCompleted } = options;

  useEffect( () => {
    //run if not disabled
    if(disabled !== true){
      //Finally fetch the data
      fetch( apiHost, {
        mode: 'cors',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          //Replace variables if needed
          query: variables ? reaplaceVariables(query, variables || {} ) : query
        }),
      })
      .then(response=>response.json())
      .then( response => {
        //Handle graph ql errors
        if(response.errors) throw response.errors.map( error => error.message ).join(', ');
        //Set data
        setData( response.data )
      })
      .catch( err => {
        console.error(err)
        setError( `Error: ${err.toString()}` );
      })
    }

  },[]) // the [] indicates that this will only happen once per mount

  return {
    data,
    setData, // Lets us modify the local data for the sake of ux/ui
    error,
    loading: (data === null && error === null) ? true : false
  }
}
